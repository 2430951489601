<template>
  <div>
    <div class="table_content">
      <!-- 表格内容 -->
      <commonTitle></commonTitle>

      <div class="search">
        <el-form ref="form" :model="form" label-width="65px" :inline="true">
          <el-form-item label="户室号:">
            <el-input v-model="form.name" size="mini" placeholder="请输入户室编号"></el-input>
          </el-form-item>

          <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
          <!--                        <button @click.prevent="toHS()" style="cursor: pointer;">户室申请</button>-->
        </el-form>
      </div>
      <!--表格-->
      <template>
        <el-card>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            v-loading="loading"
            :element-loading-text="loadingText"
            @selection-change="handleSelectionChange"
          >
            <!--                        <el-table-column-->
            <!--                                type="selection">-->
            <!--                        </el-table-column>-->
            <el-table-column property="villageName" label="小区名称" show-overflow-tooltip></el-table-column>
            <el-table-column property="villageCard" label="小区编号" show-overflow-tooltip></el-table-column>
            <el-table-column property="towerNum" label="楼栋号" show-overflow-tooltip></el-table-column>
            <el-table-column property="unitName" label="单元号" show-overflow-tooltip></el-table-column>
            <el-table-column property="houseNum" label="房间号" show-overflow-tooltip></el-table-column>

            <el-table-column label="状态" width="120" property="status" :formatter="formatStatus"></el-table-column>
            <el-table-column property="applicationApproval" label="审批意见"></el-table-column>
            <!--                        <el-table-column-->
            <!--                                fixed="right"-->
            <!--                                label="操作"-->
            <!--                                width="150">-->
            <!--                            <template slot-scope="scope">-->
            <!--                                <el-button type="text" size="small" @click="createOrder(scope.row)"-->
            <!--                                           :disabled="scope.row.status!=1">生成订单-->
            <!--                                </el-button>-->
            <!--                            </template>-->
            <!--                        </el-table-column>-->
          </el-table>
          <el-pagination
            background
            style="margin-top: 50px"
            layout="prev, pager, next"
            :page-size="15"
            @current-change="handleCurrentChange"
            :total="totalNum"
          ></el-pagination>
        </el-card>
      </template>
      <!--分页-->
    </div>
  </div>
</template>

<script>
import commonTitle from "../table/commonTitle";

export default {
  name: "ownerVillage",
  data() {
    return {
      url: this.$Config.base_server,
      form: {
        name: "",
        region: ""
      },
      tableData: [],
      multipleSelection: [],
      totalNum: 15,
      loading: false,
      loadingText: "拼命加载中...",
      data: {
        limit: 15
      }
    };
  },
  components: {
    commonTitle
  },
  mounted() {
    this.common();
  },
  methods: {
    toHS() {
      this.$router.push({ name: "applyHouse" });
    },
    common(currentPage = 0) {
      const that = this;
      that.loading = true;

      that.data.page = currentPage;
      that.data.houseNum = that.form.name; //搜索框的值
      $.ajax({
        url:
          this.url +
          "/api-public/opevillage/findByOwnerId?access_token=" +
          localStorage.getItem("token"),
        data: that.data,
        contentType: "application/json",
        type: "get",
        success: function(res) {
          that.loading = false;
          if (res.code == 0) {
            that.totalNum = res.count;
            that.tableData = res.data;
          }
        },
        error: function(err) {
          that.loading = false;
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleCurrentChange(val) {
      //当前页数改变
      this.common(val);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    formatActResult(row, column) {
      //控制审批状态
      if (row.actResult == "1") {
        return "同意";
      } else if (row.actResult == "2") {
        return "不同意";
      } else if (row.actResult == "3") {
        return "审批中";
      } else if (row.actResult == "4") {
        return "未提交";
      }
    },
    formatSettingTime(row) {
      //格式化时间
      let date = new Date(row.settingTime);
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return date.getFullYear() + "-" + month + "-" + day;
    },
    lookTower(row) {
      //查看楼幢
      //存储小区数据
      this.$router.push({ name: "ownerTower" });
      localStorage.setItem("villageData", JSON.stringify(row));
    },
    formatStatus(row) {
      //格式化状态
      if (row.status == 2) {
        return "审批拒绝";
      } else if (row.status == 0) {
        return "未提交";
      } else if (row.status == 1) {
        return "审批通过";
      } else if (row.status == 3) {
        return "审批中";
      }
    },
    createOrder(row) {
      let towerIds = [];
      let towerNums = [];
      let unitCards = [];
      let houseIds = [];
      let villageId = row.villageId;
      towerIds.push(row.towerId);
      towerNums.push(row.towerNum);
      unitCards.push(row.unitId);
      houseIds.push(row.houseId);
      //生成订单按钮
      const data = {
        busiOrderDetail: {
          villageId,
          towerIds,
          towerNums,
          unitCards, //单元id数组
          houseIds //户室id数组
        }
      };
      var that = this;
      //发送到后台生成订单接口

      this.$confirm("确定生成缴费订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(function() {
          that.loading = true;
          that.loadingText = "生成订单...";
          $.ajax({
            url:
              that.url +
              "/api-public/busiorder/save?access_token=" +
              localStorage.getItem("token"),
            data: JSON.stringify(data),
            type: "post",
            contentType: "application/json",
            success: function(res) {
              that.loading = false;
              that.$message({
                message: res.resp_msg,
                dangerouslyUseHTMLString: true,
                type: "success",
                duration: 2000,
                onClose: function() {
                  //跳转到资金管理页面
                  that.$router.push("/order");
                }
              });
            }
          });
        })
        .catch(function() {
          that.loading = false;
        });
    }
  }
};
</script>

<style scoped>
</style>