<template>
    <div>
        <div class="tab_content">
            <p><span>{{title}}操作详情</span> <span>您所在的当前位置：首页 >> {{title}}操作详情</span></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "commonTiele",
        data(){
            return{
                title:''
            }
        },
        mounted() {
            const user=JSON.parse(localStorage.getItem("user"));
            if(user.type==0){//物业用户
                this.title="物业";
            }else if(user.type==1){//开发商用户
                this.title="开发商";
            }else if(user.type==2){//业主委员会登录
                this.title="业主委员会";
            }else if(user.type==3){//业主登录
                this.title="业主";
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/table/first_table.css";
    .tab_content{
        margin-bottom: 20px;
    }
</style>